<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Bangsal'">
          <template v-slot:body>
            <div>

              <Form :form="form" :route="'beds'"/>

            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/beds/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Form
      form: {
        bed_category_id:"",
        name:"",
        description:"",
      }
    }
  },

  components: {
    Card,
    Form
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal", route: "" },
      { title: "Tambah Bangsal" },
    ])
  },

}
</script>

<style>
</style>